import React, { useEffect, useRef } from 'react';
import sc from './Confirmation.module.scss';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import BSButton from 'Components/Button/BSButton';
import { BILLING_SUBSCRIPTION } from 'constants';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import OnboardingHeader from 'Components/Pages/Onboarding/Header/Header';
import { removeRedundantDecimalPlaces } from 'helpers';
import { setLoader } from 'redux/slices/loader';
import { useDispatch } from 'react-redux';
import 'Components/PreLoader/Preloader.scss';
import { LoaderContainer, loader } from 'react-global-loader';

const intervalMapping = {
  month: 'Monthly',
  year: 'Annual',
};

const ConfirmSubscription = (summaryData) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type, planName, price } = useParams();
  const { planType, finalPrice, interval } = summaryData;
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user ?? {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const { end_date: endDate } = currentSubscription ?? {};
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trial = queryParams.get('trial') || false;
  const pixelInitialized = useRef(false);

  useEffect(() => {
    if (!pixelInitialized.current && window.fbq) {
      if (trial) {
        window.fbq('track', 'StartTrial');
      } else {
        window.fbq('track', 'Purchase', {
          value: price || summaryData?.summaryData?.finalPrice,
          currency: 'USD',
        });
      }
      pixelInitialized.current = true;
    }
  }, [trial, price, summaryData]);

  return (
    <div className={sc.confrimation__root}>
      {(trial || location.pathname.startsWith('/resubscribe-confirmation')) && (
        <OnboardingHeader />
      )}

      {/* <div
        style={{ cursor: 'pointer', fontFamily: 'Inter' }}
        onClick={() => navigate(`/${BILLING_SUBSCRIPTION}`)}
      >
        <BSButton
          variant=""
          ButtonText="Back"
          classList="back-button"
          BtnWrapperClass="back-button"
          icon={<img src="/Images/arrowLeft.svg" alt="arrow" />}
        />
      </div> */}
      <div className={sc.thankYouWrapper}>
        <span>
          <img src="/Images/check-green.svg" alt="checkIcon" />
        </span>
        <h2 className={sc.heading}>Thank You!</h2>
        <p className={sc.desc}>
          You are now subscribed to our{' '}
          <strong>
            {intervalMapping[type] ||
              intervalMapping[summaryData?.summaryData?.interval]}{' '}
            {planName || summaryData?.summaryData?.planType}
          </strong>
          . We are thrilled to have you as a part of our community.
        </p>
        <div className={sc.outer_card}>
          <p>Subscription details</p>
          <span>Here's the summary of your subscription plan</span>
          <div className={sc.inner_card}>
            {/* <div className={sc.flex}>
              <p>Order Number</p>
              <span>#12345</span>
            </div> */}
            <div className={sc.flex}>
              <p>Plan - {planName || summaryData?.summaryData?.planType}</p>
              <span>One Person</span>
            </div>
            <div className={sc.flex}>
              <p>Billing Cycle</p>
              <span>
                {intervalMapping[type] ||
                  intervalMapping[summaryData?.summaryData?.interval]}
              </span>
            </div>
            <div className={sc.flex}>
              <p>{trial ? 'Total' : 'Total Amount Paid'}</p>
              <span>
                ${' '}
                {removeRedundantDecimalPlaces(
                  price || summaryData?.summaryData?.finalPrice,
                )}
                /{type || summaryData?.summaryData?.planType}
              </span>
            </div>
          </div>
        </div>
        <div className={sc.thankYouWrapper__footer}>
          <HiOutlineExclamationCircle
            className={sc.thankYouWrapper__footer__icon}
          />
          <span>
            The next payment will be charged at{' '}
            {moment(endDate ?? new Date())
              .add(endDate ? 0 : 1, type === 'month' ? 'months' : 'years')
              .format('MM/DD/YYYY')}
            .
          </span>
        </div>
      </div>
      <div className={sc.button_section}>
        {/* <BSButton
          variant="dark"
          ButtonText="Get Receipt"
          classList="cancel_btn"
          BtnWrapperClass="back-button"
          icon={<img src="/Images/download.svg" alt="arrow" />}
        /> */}
        <BSButton
          variant=""
          ButtonText={
            currentSubscription === null || currentSubscription === undefined
              ? 'Please wait...'
              : 'Generate Content Plan'
          }
          classList="cancel_btn"
          BtnWrapperClass="back-button"
          iconPosition="right"
          onClick={() => {
            navigate('/content-planner');
          }}
          icon={
            currentSubscription !== null &&
            currentSubscription !== undefined && (
              <img src="/Images/chevron-right.svg" alt="arrow" />
            )
          }
          disabled={
            currentSubscription === null || currentSubscription === undefined
          }
        />
      </div>
    </div>
  );
};

export default ConfirmSubscription;
