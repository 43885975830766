import React, { useState, useEffect } from 'react';
import Styles from './BrandSummary.module.scss';
import FormikTextField from 'Components/Form/FormikTextField';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import onBoardingService from 'services/onBoardingService';
import * as Yup from 'yup';
import TagInput from 'Components/Input/TagsInput/TagInput';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import BSButton from 'Components/Button/BSButton';
import LeftNavigation from './LeftNavigation';
import { setLoader } from 'redux/slices/loader';
import { actions } from 'redux/slices/auth';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import ConfirmationModal from 'Containers/Modal/ConfirmationModal/ConfirmationModal';

const BrandSummary = ({
  setLoading,
  url,
  setBrandSummary,
  description,
  setBrandName,
  currentSubscription,
  brandName,
  test,
  user,
  setChangeAdd,
  changeAdd,
  setUrl,
  brandSummary,
  setBrandSummaryData,
  brandSummaryData,
  initialValues,
  setInitialValues,
  brandImage,
  setBrandImage,
  setDescription,
  fields,
  setFields,
  cancelClicked,
  setCancelClicked,
}) => {
  const dispatch = useDispatch();
  const [brand, setBrand] = useState('');
  const [brandsData, setBrandsData] = useState({});
  const [displayUrl, setDisplayUrl] = useState('');
  const [formType, setFormType] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const navigate = useNavigate();
  // const [topicsError, setTopicError] = useState(false);

  const emptyStateSummary = {
    brand_name: '',
    brand_description: '',
    core_messaging: '',
    value_proposition: '',
    industries: [],
    keywords_and_themes: [],
    target_audience: [],
    tone: [],
    detailed_topics: [],
    logo: brandImage,
  };

  const [updateTime, setUpdateTime] = useState('');
  const [hasId, setHasId] = useState({
    id: '',
    state: false,
  });
  const [showContentGenerateButton, setShowContentGenerateButton] =
    useState(false);

  let interval;
  let timeoutCounter = 0;
  const maxTimeout = 80000;

  const validationSchema = Yup.object().shape({
    brand_name: Yup.string().trim().required('Brand name is required'),
    brand_description: Yup.string()
      .trim()
      .required('Brand description is required'),
    core_messaging: Yup.string().trim().required('Core messaging is required'),
    value_proposition: Yup.string()
      .trim()
      .required('Value proposition is required'),
    industries: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Industry is required'),
    keywords_and_themes: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Keyword is required'),
    target_audience: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Target audience is required'),
    tone: Yup.array().of(Yup.string().required()).min(1, 'Tone is required'),
  });

  const handleDeleteBrand = async (id) => {
    try {
      const response = await onBoardingService.deleteBrand(id);
      if (response.data.success) {
        successToast(response.data.message);

        setBrandSummary(false);

        setChangeAdd(true);
        setBrand('');
        setBrandName('');
        setUrl('');
        setBrandSummaryData(false);
        setBrandImage(null);
        setDescription('');
        setInitialValues(emptyStateSummary);

        fetchBrands();
      } else {
        errorToast(response.data.error.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAutofill = async (url) => {
    try {
      setLoading(true);
      const response = await onBoardingService.getAnalyzeBusiness(url);
      if (response?.data) {
        pollFetchScrappedBusiness(url);
      } else {
        errorToast('There is some issue while scrapping. Please try Again');
        setBrandSummary(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.error ||
        'We are unable to fetch data from scraper. Try Again';
      errorToast(errorMessage);
      setBrandSummary(false);
    }
  };

  const handleError = () => {
    setLoading(false);
    setBrandSummary(false);
    errorToast('We are unable to fetch data from scraper. Try Again');
  };

  const handleFieldChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, value } : field,
    );
    setFields(updatedFields);
  };

  const handleAddField = () => {
    const newField = { id: Date.now(), name: 'niche', value: '' };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const handleRemoveField = (index) => {
    if (fields.length === 1) {
      errorToast('Atleast 1 Topic is required');
      return;
    }
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const postFailedScrapper = async (url) => {
    try {
      await onBoardingService.postFailedScrapper(url);
    } catch (error) {
      console.error('Failed to post failed scraper:', error);
    }
  };

  const pollFetchScrappedBusiness = (url) => {
    interval = setInterval(async () => {
      try {
        let response = await onBoardingService.getScrappedBusiness(url);
        if (response?.data?.scraper_status === 'completed') {
          clearInterval(interval);
          if (response?.data?.response) {
            setFields(
              response?.data?.response?.detailed_topics.map((topic, index) => ({
                id: index,
                name: 'niche',
                value: topic,
              })),
            );
            setInitialValues({
              brand_name: response?.data?.response?.brand_name || '',
              brand_description:
                response?.data?.response?.brand_description || '',
              core_messaging: response?.data?.response?.core_messaging || '',
              value_proposition:
                response?.data?.response?.value_proposition || '',
              industries: response?.data?.response?.industries || [],
              keywords_and_themes:
                response?.data?.response?.keywords_and_themes || [],
              target_audience: response?.data?.response?.target_audience || [],
              tone: response?.data?.response?.tone || [],
              logo: response?.data?.response?.logo || null,
            });
            setBrandName(response?.data?.response?.brand_name);
            setBrandSummaryData(true);
            setLoading(false);
            dispatch(setLoader({ isLoading: false }));
          }
        } else if (response?.data?.scraper_status === 'failed') {
          handleError();
          clearInterval(interval);
        } else {
          timeoutCounter += 10000; // Polling interval  (10 seconds)
          if (timeoutCounter >= maxTimeout) {
            handleError();
            clearInterval(interval);
            await postFailedScrapper(url);
          }
        }
      } catch (error) {
        handleError();
        clearInterval(interval);
      }
    }, 10000); // Poll every 10 seconds
  };

  const fetchBrands = async () => {
    try {
      const response = await onBoardingService.getUserBrandInfo();
      dispatch(actions.updateUser({ user: { user_brands: response?.data } }));
      setBrandsData(response?.data?.brands_data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = async (values, validateForm) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        errorToast('Please fill all required fields.');
        return;
      }

      let body;
      const topicsArray = fields.map((field) => field.value);
      const { logo, ...logoRemovedValues } = values;

      if (description) {
        body = {
          user_entered_summary: logoRemovedValues,
          description: description,
          brand_name: values.brand_name,
          logo: values.logo || null,
          content_topics: topicsArray,
        };
      } else {
        body = {
          auto_generated_summary: logoRemovedValues,
          brand_name: values.brand_name,
          url: url,
          logo: values.logo || null,
          content_topics: topicsArray,
        };
      }
      const response = await onBoardingService.createBusiness(body);
      if (response?.data?.success) {
        response?.data?.business?.id &&
          setHasId({ state: true, id: response?.data?.business?.id });
        setBrandName(response?.data?.business?.brand_name);
        setBrand(response?.data?.business?.id);
        successToast('Brand Created!');
        fetchBrands();
      } else {
        errorToast('An error occurred 1: ' + response.message);
      }
    } catch (error) {
      console.error(error);
      errorToast('An error occurred 2: ' + error.message);
    }
  };

  const editBusiness = async (values, validateForm) => {
    try {
      const errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        errorToast('Please fill all required fields.');
        return;
      }

      let body;
      const topicsArray = fields.map((field) => field.value);
      const { logo, ...logoRemovedValues } = values;

      body = {
        summary: logoRemovedValues,
        logo: values.logo,
        content_topics: topicsArray,
        brand_name: values.brand_name,
      };

      const response = await onBoardingService.updateBusinessById(
        hasId.id,
        body,
      );
      if (response?.data) {
        response?.data?.business?.id &&
          setHasId({ state: true, id: response?.data?.business?.id });
        setBrandName(
          response?.data?.user_entered_summary?.brand_name ||
            response?.data?.auto_generated_summary?.brand_name,
        );
        successToast('Brand Updated!');
        fetchBrands();
      } else {
        errorToast('An error occurred 1: ' + response.message);
      }
    } catch (error) {
      console.error(error);
      errorToast('An error occurred 2: ' + error.message);
    }
  };

  const fetchData = async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching!',
          description: 'Please wait...',
        }),
      );
      const data = await onBoardingService.getBusinessById(
        brand !== ''
          ? brand
          : Object.keys(user?.user_brands?.brands_data[0])[0],
      );
      let summary =
        data?.data?.auto_generated_summary || data?.data?.user_entered_summary;
      setInitialValues({
        brand_name: summary?.brand_name || '',
        brand_description: summary?.brand_description || '',
        core_messaging: summary?.core_messaging || '',
        value_proposition: summary?.value_proposition || '',
        industries: summary?.industries || summary?.industry || [],
        keywords_and_themes: summary?.keywords_and_themes || [],
        target_audience: summary?.target_audience || [],
        tone: summary?.tone || [],
        logo: data?.data?.logo || null,
      });

      setFields(
        data?.data?.content_topics.map((topic, index) => ({
          id: index,
          name: 'niche',
          value: topic,
        })),
      );
      setDisplayUrl(data?.data?.url);
      setBrandImage(data?.data?.logo || null);
      setShowContentGenerateButton(data?.data?.user_entered_summary !== null);
      setBrandName(summary?.brand_name || '');
      setHasId({ state: true, id: data?.data?.id });
      setUpdateTime(data?.data?.updated_at);
      setCancelClicked(false);
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      console.error('Error fetching brand data:', error);
      setCancelClicked(false);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleCancel = (setValues) => {
    setValues(emptyStateSummary);
    navigate('/');
  };

  const generateTopics = async (values, setFieldValue, validateForm) => {
    const errors = await validateForm();
    if (
      Object.keys(errors).length > 0 &&
      !(
        Object.keys(errors).length === 1 &&
        Object.keys(errors)[0] === 'detailed_topics'
      )
    ) {
      errorToast('Please fill in all fields to generate topics');
      return;
    }
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Generating!',
          description: 'Please wait...',
        }),
      );
      const { logo, ...userEnteredValues } = values;

      const body = {
        user_entered_summary: userEnteredValues,
        brand_name: values.brand_name,
      };
      const response = await onBoardingService.getContentTopics(body);
      if (response?.data?.success) {
        setFields(
          response?.data?.content_topics.map((topic, index) => ({
            id: index,
            name: 'niche',
            value: topic,
          })),
        );
        dispatch(setLoader({ isLoading: false }));
        setShowContentGenerateButton(true);
      } else {
        errorToast('An error occurred 1: ' + response.message);
        dispatch(setLoader({ isLoading: false }));
      }
    } catch (error) {
      console.error(error);
      errorToast('An error occurred 2: ' + error.message);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  useEffect(() => {
    if (brand || !changeAdd || cancelClicked) {
      if (description === '' && url === '') {
        fetchData();
      }
    }
  }, [brand]);

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    if (url && !brandSummaryData) {
      handleAutofill(url);
    } else {
      setLoading(false);
    }
    return () => clearInterval(interval);
  }, [url, brandSummaryData]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = (values, type, setFieldValue, validateForm) => {
    if (type === 'generate') {
      generateTopics(values, setFieldValue, validateForm);
    } else if (type === 'save') {
      if (hasId.state) {
        editBusiness(values, validateForm);
      } else {
        handleNext(values, validateForm);
      }
    }
  };

  const formRef = React.useRef();

  const handleClick = (
    type,
    values,
    setFieldValue,
    validateForm,
    submitForm,
  ) => {
    setFormType(type);
    submitForm();
    // handleSubmit(values, type, setFieldValue, validateForm);
  };

  return (
    <div className={Styles.container}>
      <LeftNavigation
        test={test}
        brandName={brandName}
        url={url || displayUrl}
        currentSubscription={currentSubscription}
        setBrandSummary={setBrandSummary}
        brandsData={user?.user_brands?.brands_data}
        brand={brand}
        setBrand={setBrand}
        setBrandImage={setBrandImage}
        brandImage={brandImage}
        setBrandName={setBrandName}
        setUrl={setUrl}
        setChangeAdd={setChangeAdd}
        updateTime={updateTime}
        setBrandSummaryData={setBrandSummaryData}
        setDescription={setDescription}
        setInitialValues={setInitialValues}
        emptyStateSummary={emptyStateSummary}
        description={description}
        showContentGenerateButton={showContentGenerateButton}
        submitForm={() => formRef.current.submitForm()} // Pass submitForm here
        handleDeleteBrand={handleDeleteBrand}
        setCancelModalOpen={setCancelModalOpen}
        hasId={hasId}
        handleClick={(type) => {
          if (formRef.current) {
            const formik = formRef.current;
            handleClick(
              type,
              formik.values,
              formik.setFieldValue,
              formik.validateForm,
              formik.submitForm,
            );
          } else {
            console.error('formRef.current is not defined');
          }
        }}
      />

      <div className={Styles.brandSummary_container}>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
          // onSubmit={handleSubmit}
          onSubmit={(values, { setFieldValue, validateForm }) => {
            handleSubmit(values, formType, setFieldValue, validateForm);
          }}
        >
          {({ values, setFieldValue, submitForm, validateForm }) => {
            // const handleClick = (type) => {
            //   // submitForm();
            // };

            useEffect(() => {
              setFieldValue('logo', brandImage);
            }, [brandImage]);

            return (
              <>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  className={Styles.form2_wrapper}
                >
                  <div
                    className={`${Styles.textarea_wrapper} ${Styles.bg_yellow}`}
                  >
                    <FormikTextField
                      id="brand_name"
                      name="brand_name"
                      type="text"
                      placeholder="Enter brand name..."
                      Label="Brand Name"
                      classList="input form-control w-100"
                      isRequired
                      // disabled
                      // isTextArea
                    />
                    <ErrorMessage
                      name="brand_name"
                      component="div"
                      className="error mt-2"
                    />
                    {/* <label>Brand Name</label><br/>
                    <span>{values.brand_name}</span> */}
                  </div>
                  <div
                    id="brand_description"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_purpel} clickable`}
                  >
                    <FormikTextField
                      id="brand_description"
                      name="brand_description"
                      type="text"
                      placeholder="Enter brand description..."
                      Label="Brand Description"
                      classList="input form-control text-area-height w-100"
                      isRequired
                      isTextArea
                    />
                    <ErrorMessage
                      name="brand_description"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <div
                    id="core_messaging"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_yellow} clickable`}
                  >
                    <FormikTextField
                      id="core_messaging"
                      name="core_messaging"
                      type="text"
                      placeholder="The brand voice is very professional, informative and inclusive, prioritising clarity, precision, consistency, transparency and creative while emphasising trust worthiness, diversity and innovation in a friendly yet formal tone."
                      Label="Core Messaging"
                      classList="input form-control text-area-height w-100"
                      isRequired
                      isTextArea
                    />
                    <ErrorMessage
                      name="core_messaging"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <div
                    id="audience"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_purpel} clickable`}
                  >
                    <TagInput
                      name="target_audience"
                      label="Audience"
                      tags={values.target_audience}
                      onChange={(value) =>
                        setFieldValue('target_audience', value)
                      }
                      placeholder="Define target audience..."
                      isRequired
                      showError={true}
                    />
                    <ErrorMessage
                      name="target_audience"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <div
                    id="value_propositions"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_yellow}   clickable`}
                  >
                    <FormikTextField
                      id="value_proposition"
                      name="value_proposition"
                      type="text"
                      placeholder="Innovation: Continuously pushing the boundaries of AI to simplify content creation and marketing.
                Efficiency: Streamlining processes to save time and enhance productivity.
                Creativity: Empowering users to break through creative blocks and generate fresh, impactful content.
                Collaboration: Facilitating teamwork and seamless collaboration within content operations.
                User-Centricity: Designing tools and features with the end-user's needs and goals in mind."
                      Label="Value Proposition"
                      classList="input form-control text-area-height w-100"
                      isRequired
                      isTextArea
                    />
                    <ErrorMessage
                      name="value_proposition"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <div
                    id="tone_of_voice"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_purpel} clickable`}
                  >
                    <TagInput
                      name="tone"
                      label="Tone of Voice"
                      tags={values.tone}
                      onChange={(value) => setFieldValue('tone', value)}
                      placeholder="Define tone..."
                      isRequired
                      showError={true}
                    />
                    <ErrorMessage
                      name="tone"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <div
                    id="industry"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_yellow} clickable`}
                  >
                    <TagInput
                      name="industries"
                      label="Industry"
                      tags={values.industries}
                      onChange={(value) => setFieldValue('industries', value)}
                      placeholder="Enter Industry..."
                      isRequired
                      showError={true}
                    />
                    <ErrorMessage
                      name="industries"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <div
                    id="keywords_themes"
                    className={`${Styles.textarea_wrapper} ${Styles.bg_purpel} clickable`}
                  >
                    <TagInput
                      name="keywords_and_themes"
                      label="Keywords & Themes"
                      tags={values.keywords_and_themes}
                      onChange={(value) =>
                        setFieldValue('keywords_and_themes', value)
                      }
                      placeholder="Enter keywords and themes..."
                      isRequired
                      showError={true}
                    />
                    <ErrorMessage
                      name="keywords_and_themes"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  {(!description || showContentGenerateButton) && (
                    <div
                      id="detailed_topics"
                      className={`${Styles.textarea_wrapper} ${Styles.bg_yellow} clickable`}
                    >
                      <label
                        htmlFor=""
                        className={`${Styles.textarea_wrapper__label} ${Styles.flex_label_button} Input_label`}
                      >
                        <div>
                          Topics <span className="text-danger">*</span>
                        </div>
                        {(description || showContentGenerateButton) && (
                          <BSButton
                            ButtonText={'Generate Topics'}
                            classList="secondary_btn w-100 "
                            onClick={() =>
                              generateTopics(
                                values,
                                setFieldValue,
                                validateForm,
                              )
                            }
                            style={{
                              margin: '5px',
                            }}
                          />
                        )}
                      </label>
                      <div className={Styles.form3_wrapper}>
                        {fields.map((field, index) => {
                          return (
                            <div
                              key={field.id}
                              className={Styles.form3_wrapper__field}
                            >
                              <div className="w-100">
                                <input
                                  type="text"
                                  placeholder="Describe topic..."
                                  className={Styles.input_content}
                                  value={field.value}
                                  onChange={(e) =>
                                    handleFieldChange(index, e.target.value)
                                  }
                                />
                              </div>
                              <button
                                type="button"
                                className={Styles.form3_wrapper__field__button}
                                onClick={() => handleRemoveField(index)}
                              >
                                <AiOutlineDelete size={20} color="#667085" />
                              </button>
                            </div>
                          );
                        })}
                        {/* {topicsError && <div className='error mt-2'>Topics are required</div>} */}
                      </div>
                      <button
                        type="button"
                        className={Styles.form3_wrapper__another_field_btn}
                        onClick={handleAddField}
                      >
                        <FiPlus size={20} color="#00a7b7" />
                        <span>Add another content topic</span>
                      </button>
                    </div>
                  )}
                  {/* {description && !showContentGenerateButton ? (
                    <BSButton
                      ButtonText={'Generate Topics'}
                      classList="secondary_btn w-100"
                      // onClick={() =>
                      //   generateTopics(values, setFieldValue, validateForm)
                      // }
                      onClick={() => handleClick('generate',values,setFieldValue,validateForm)} // Pass 'generate' type

                    />
                  ) : (
                    <BSButton
                      // ButtonText={hasId.state ? 'Edit' : 'Save'}
                      ButtonText={'Save'}
                      classList="secondary_btn w-100"
                      // onClick={()=>handleClick(values,"save")}
                      onClick={() => handleClick('save',values,setFieldValue,validateForm)} // Pass 'save' type

                    />
                  )} */}
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
      <ConfirmationModal
        show={cancelModalOpen}
        onHide={() => setCancelModalOpen(false)}
        onDelete={() => handleDeleteBrand(hasId.id)}
        modalHeading="Confirm Delete Brand"
        modalDescription={'Are you sure you want to delete this brand?'}
        actionText="Proceed"
        loaderHeading="Processing!"
      />
    </div>
  );
};

export default BrandSummary;
