import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import { success as renderSuccess } from 'helpers/toaster.js';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import Drawer from 'Components/Drawer/Drawer';
import Loader from 'Components/Loader/Loader';
import Styles from './ProjectDrawer.module.scss';
import {
  CONTENT_TYPE_OPTIONS,
  CAMPAIGN_GOALS,
} from '../../constants/contentTypes';
import Dropdown from 'Components/Dropdown/Dropdown';
import FormikDateField from 'Components/Form/FormikDateField';
import { manualProjectCreateSchema } from 'schemas/projectSchemas';
import useCreateProject from 'hooks/projects/useCreateProject';
import useUpdateProject from 'hooks/projects/useUpdateProject';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AiPoweredGeneratedTabs from './ProjectTile/AiPoweredGeneratedTabs/AiPoweredGeneratedTabs';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
import { ReactComponent as Other } from '../../assets/svg/Group.svg';
import { ReactComponent as X } from '../../assets/svg/x.svg';
import { ReactComponent as Linkedin } from '../../assets/svg/linkedin.svg';
import { ReactComponent as Youtube } from '../../assets/svg/youtube.svg';
import { ReactComponent as Blog } from '../../assets/svg/blog.svg';
import { ReactComponent as CaseStudy } from '../../assets/svg/case-study.svg';
import { ReactComponent as Guide } from '../../assets/svg/Guide.svg';
import { ReactComponent as Quiz } from '../../assets/svg/quiz.svg';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as TikTok } from '../../assets/svg/tik-tok.svg';

import Tag from 'Components/tag/Tag';
import { useNavigate } from 'react-router-dom';

const ProjectDrawer = ({ show, onHide, action, project }) => {
  const tags = [
    {
      id: 'linkedin',
      title: 'Linkedin Post',
      selected: false,
      icon: <Linkedin />,
    },
    {
      id: 'linkedin-article',
      title: 'Linkedin Article',
      selected: false,
      icon: <Linkedin />,
    },

    {
      id: 'facebook-post',
      title: 'Facebook Post',
      selected: false,
      icon: <Facebook />,
    },
    {
      id: 'instagram',
      title: 'Instagram Carousel',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'tiktok-video',
      title: 'TikTok Video',
      selected: false,
      icon: <TikTok />,
    },
    {
      id: 'instagram-post',
      title: 'Instagram Post',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'instagram-reel',
      title: 'Instagram Reel',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'medium',
      title: 'Medium Article',
      selected: false,
      icon: <Other />,
    },
    {
      id: 'twitter',
      title: 'Twitter Thread',
      selected: false,
      icon: <X />,
    },
    {
      id: 'youtube',
      title: 'Youtube Script',
      selected: false,
      icon: <Youtube />,
    },
    {
      id: 'blog',
      title: 'Blog Post',
      selected: false,
      icon: <Blog />,
    },
    {
      id: 'case',
      title: 'Case Study',
      selected: false,
      icon: <CaseStudy />,
    },
    {
      id: 'guides',
      title: 'Guide',
      selected: false,
      icon: <Guide />,
    },
    {
      id: 'quiz',
      title: 'Quiz',
      selected: false,
      icon: <Quiz />,
    },
  ];
  const navigate = useNavigate();

  const isEdit = action !== 'create';
  const { user } = useSelector((state) => state.auth);

  const { mutateAsync: createProject, isLoading: isCreateLoading } =
    useCreateProject({
      userId: user?.id,
    });

  const { mutateAsync: updateProject, isLoading: isUpdateLoading } =
    useUpdateProject();

  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const defaultType = 'ai_powered';
  const [tagState, setTagState] = useState(tags);
  const [selectedTags, setSelectedTags] = useState([]);
  const [creationType, setCreationType] = useState(
    project?.creation_type || defaultType,
  );
  const contentNo = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '25', value: 25 },
  ];

  useEffect(() => {
    if (project?.content_types) {
      const selectedContentTypes = project.content_types.split(', ');
      setTagState((prevTags) =>
        prevTags.map((tag) => ({
          ...tag,
          selected: selectedContentTypes.includes(tag.title),
        })),
      );
    }
  }, [project]);

  const initialValues = useMemo(() => {
    return {
      name: project?.name || '',
      description: project?.description || '',

      content_types: project?.content_types
        ? project?.content_types
            .split(',')
            .map((val) => ({ label: val, value: val }))
        : [],
      // campaign_goals: project?.campaign_goals
      //   ? project?.campaign_goals
      //     .split(',')
      //     .map((val) => ({ label: val, value: val }))
      //   : [],
      campaign_goal: project?.campaign_goal || '', // or another appropriate usage
      // brand: project?.brand || '',
      brand: project?.business_id || '',
      content_no: 10,

      // campaign_goals: project?.campaign_goals
      // ? { label: project?.campaign_goals, value: project?.campaign_goals }
      // : '',
      target_audience: project?.target_audience || '',
      key_message: project?.key_message || '',
      start_date: project?.start_date
        ? new Date(project?.start_date)
        : new Date(),
      end_date: project?.end_date ? new Date(project?.end_date) : new Date(),
    };
  }, [project]);

  const modalTitle =
    action === 'create' ? 'Create New Campaign' : 'Edit Campaign';

  const getSelectedTagsString = (tags) => {
    return tags
      .filter((tag) => tag.selected)
      .map((tag) => tag.title)
      .join(', ');
  };

  function transformBrandsData(brandsData) {
    return brandsData?.map((item) => {
      const [key, value] = Object.entries(item)[0];
      if (value === null) {
        return { label: 'Unknown', value: key };
      }
      return { label: value, value: key };
    });
  }

  const handleSubmit = async (values) => {
    const selectedTagsString = getSelectedTagsString(tagState);

    const data = {
      ...values,
      // content_types: values.content_types.map(({ value }) => value).toString(),
      // campaign_goals: values.campaign_goals.map(({ value }) => value),
      business_id: values?.brand,
      campaign_goal: values.campaign_goal,
      content_types: selectedTagsString,
      creation_type: creationType,
    };

    const response = isEdit ? updateProject : createProject;

    const payload = {
      body: data,
      ...(isEdit && { projectId: project?.id }),
    };

    await response(payload, {
      onSuccess: async (response) => {
        onHide();
        renderSuccess(response?.data?.message);
        // if(!isEdit){
        // if(creationType === "ai_powered"){
        navigate(
          `/projects/${response?.data?.project?.id}/newPlan?isManual=false`,
          {
            state: {
              project: response?.data?.project,
              campaignCards: true,
              amount: values?.content_no,
              customAmount: true,
            },
          },
        );
        // }
        // }
      },
    });
  };

  return (
    <>
      {isCreateLoading || isUpdateLoading ? (
        <Loader
          inline
          loaderHeading="Saving Project!"
          loaderDescription="Please wait..."
        />
      ) : (
        <Drawer
          show={show}
          onHide={onHide}
          placement="end"
          title={modalTitle}
          width={650}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={manualProjectCreateSchema(isEdit)}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values, touched, errors }) => {
              return (
                <Form
                  className={Styles.Drawer_Form}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <div className={Styles.Drawer_Form_InputWrapper}>
                    <div className={Styles.Drawer_Form_InputWrapper_Input}>
                      <FormikTextField
                        name="name"
                        type="string"
                        placeholder="Add campaign title…"
                        Label="Campaign Name"
                        classList="input"
                        isRequired
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error mt-2"
                      />
                    </div>
                    <div
                      className={`${Styles.Drawer_Form_InputWrapper_Dates} w-100`}
                    >
                      <div className={Styles.Drawer_Form_InputWrapper_Input}>
                        <FormikTextField
                          name="target_audience"
                          type="string"
                          placeholder="Ex: College students"
                          Label="Target Audience"
                          classList="input form-control"
                          isRequired
                        />
                        <ErrorMessage
                          name="target_audience"
                          component="div"
                          className="error mt-2"
                        />
                      </div>
                      <div className={Styles.Drawer_Form_InputWrapper_Input}>
                        <Dropdown
                          name="campaign_goal"
                          label="Campaign Goals"
                          placeholder="Select Campaign Goal"
                          // options={CAMPAIGN_GOALS}
                          options={CAMPAIGN_GOALS.map((option) => ({
                            label: option.label,
                            value: option.value,
                          }))}
                          // value={values.campaign_goals.map((val) =>
                          //   CAMPAIGN_GOALS.find((option) => option.value === val),
                          // )}
                          value={
                            values.campaign_goal
                              ? CAMPAIGN_GOALS.find(
                                  (option) =>
                                    option.value === values.campaign_goal,
                                )
                              : null
                          }
                          isRequired
                          defaultValue={initialValues.campaign_goal}
                          onChange={(option) =>
                            setFieldValue('campaign_goal', option.value)
                          }
                          // isClearable
                        />
                      </div>
                    </div>
                    <div className={Styles.Drawer_Form_InputWrapper_Input}>
                      <FormikTextField
                        id="description"
                        name="description"
                        type="text"
                        // placeholder="i.e we're launching a new look for 'Vocable,' highlighting our commitment to innovation and customer satisfaction."
                        placeholder={`Generate a campaign for the "Vocable Product Launch" to introduce our AI-powered content marketing software by driving brand awareness through organic content. We will highlight how our software solves common challenges like time-consuming content creation and difficulty in maintaining consistency, positioning it as an essential tool for modern marketers.`}
                        Label="Campaign Description"
                        classList="input form-control"
                        isRequired
                        isTextArea
                        // iconWithLabel={true}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error mt-2"
                      />
                    </div>
                    <div className={Styles.Drawer_Form_InputWrapper_Input}>
                      <Dropdown
                        name="brand"
                        label="Brand"
                        placeholder="Select Brand"
                        options={transformBrandsData(
                          user?.user_brands?.brands_data || {},
                        )}
                        // defaultValue={initialValues.brand}
                        isClearable
                        value={
                          transformBrandsData(
                            user?.user_brands?.brands_data,
                          ).find((option) => option.value == values.brand) || ''
                        }
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            'brand',
                            selectedOptions ? selectedOptions.value : '',
                          );
                        }}
                      />
                    </div>
                    <div className={Styles.Drawer_Form_InputWrapper_Input}>
                      <FormikTextField
                        id="key_message"
                        name="key_message"
                        type="text"
                        placeholder="Write key messaging"
                        Label="Key Messaging"
                        classList="input form-control"
                        rows={2}
                        isTextArea
                      />
                    </div>
                    <div
                      className={`${Styles.Drawer_Form_InputWrapper_Dates} w-100`}
                    >
                      <div className="w-100">
                        <FormikDateField
                          name="start_date"
                          label="Start Date"
                          isRequired
                        />
                      </div>
                      <div className="w-100">
                        <FormikDateField
                          name="end_date"
                          label="End Date"
                          isRequired
                        />
                      </div>
                    </div>
                    {/* <div className={Styles.Drawer_Form_InputWrapper_Input}>
                    <Dropdown
                      name="content_types"
                      label="Content Type"
                      placeholder="Select Content Type"
                      options={CONTENT_TYPE_OPTIONS}
                      isRequired
                      defaultValue={initialValues.content_types}
                      onChange={(option) =>
                        setFieldValue('content_types', option)
                      }
                      isMulti
                      isClearable
                    />
                  </div> */}
                    <div className={Styles.form4_wrapper__tags_container}>
                      <label htmlFor="" className="Input_label form-label">
                        Content Type <span className="text-danger"> *</span>
                      </label>
                      <div
                        className={Styles.form4_wrapper__tags_container__outer}
                      >
                        {tagState?.map((tag) => (
                          <Tag
                            key={tag.id}
                            tag={tag}
                            setTags={setTagState}
                            tags={tagState}
                            Tagclasses={
                              Styles.form4_wrapper__tags_container__outer__tag
                            }
                            iconLeft={tag.icon}
                            selected={tag.selected}
                            callClickHandler={true}
                            onClick={() => {
                              const newTagState = tagState.map((t) =>
                                t.id === tag.id
                                  ? { ...t, selected: !t.selected }
                                  : t,
                              );
                              setTagState(newTagState);
                              setFieldValue(
                                'content_types',
                                newTagState
                                  .filter((t) => t.selected)
                                  .map((t) => t.title),
                              );
                            }}
                          />
                        ))}
                      </div>
                      {touched.content_types && errors.content_types ? (
                        <div className="error mt-2">{errors.content_types}</div>
                      ) : null}
                    </div>
                    <div className={Styles.Drawer_Form_InputWrapper_Input}>
                      <FormikTextField
                        id="content_no"
                        name="content_no"
                        type="number"
                        placeholder="Enter No. of contents"
                        Label="No. of Content"
                        classList="input form-control"
                        isRequired
                        iconWithLabel={true}
                        infoTooltip={'Please enter a number between 1 and 30.'}
                        inputProps={{ min: 1, max: 30 }}
                      />

                      <ErrorMessage
                        name="content_no"
                        component="div"
                        className="error mt-2"
                      />

                      {/* <Dropdown
                    name="content_no"
                    label="No. of Content"
                    options={contentNo}
                    isRequired
                    placeholder="Select an Option"
                    value={contentNo.find(
                      (option) => option.value === values.content_no,
                    )}
                    // value={values.content_no}
                    onChange={(selectedOption) =>
                      setFieldValue(
                        'content_no',
                        selectedOption ? selectedOption.value : '',
                      )
                    }
                  /> */}
                    </div>
                  </div>
                  {/* {action === 'create' && <div className={Styles.tabs_container}>
                  <div className={Styles.tabs_container__header}>
                    <p className={Styles.tabs_container__header__heading}>
                      CREATION TYPE
                    </p>
                    <p className={Styles.tabs_container__header__description}>
                      Select one from the options
                    </p>
                  </div>
                  <AiPoweredGeneratedTabs creationType={creationType} setCreationType={setCreationType} plan={currentSubscription?.plan_name} />
                </div>} */}
                  {/* <ProgressBar now={6} className="drawer-progress-bar" /> */}
                  <div
                    className="d-flex justify-content-end align-self-end align-bottom drawer-footer"
                    style={{ gap: '7px' }}
                  >
                    {action !== 'create' && (
                      <BSButton
                        variant="light"
                        style={{ padding: '10px 16px' }}
                        ButtonText={'Cancel'}
                        classList={'btn button cancel_btn'}
                        onClick={onHide}
                      />
                    )}
                    <BSButton
                      variant="dark"
                      style={{ padding: '10px 16px' }}
                      ButtonText={
                        action === 'create'
                          ? creationType === 'self_powered'
                            ? 'Save'
                            : 'Continue'
                          : 'Update And Regenerate'
                      }
                      classList="secondary_btn"
                      type="submit"
                      disabled={!isValid}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Drawer>
      )}
    </>
  );
};

export default ProjectDrawer;