import React, { useEffect, useState } from 'react';
import Drawer from 'Components/Drawer/Drawer';
import Styles from '../../../../src/Components/Pages/Onboarding/Forms/Styles.module.scss';
import { Form, ProgressBar } from 'react-bootstrap';
import { ReactComponent as Instagram } from '../../../assets/svg/instagram.svg';
import { ReactComponent as Other } from '../../../assets/svg/Group.svg';
import { ReactComponent as X } from '../../../assets/svg/x.svg';
import { ReactComponent as Linkedin } from '../../../assets/svg/linkedin.svg';
import { ReactComponent as Youtube } from '../../../assets/svg/youtube.svg';
import { ReactComponent as Blog } from '../../../assets/svg/blog.svg';
import { ReactComponent as CaseStudy } from '../../../assets/svg/case-study.svg';
import { ReactComponent as Guide } from '../../../assets/svg/Guide.svg';
import { ReactComponent as Quiz } from '../../../assets/svg/quiz.svg';
import { ReactComponent as TikTok } from '../../../assets/svg/tik-tok.svg';
import { ReactComponent as Facebook } from '../../../assets/svg/facebook.svg';
import Tag from 'Components/tag/Tag';
import { ErrorMessage, Formik, FieldArray } from 'formik';
import { FiPlus } from 'react-icons/fi';
import FormikTextField from 'Components/Form/FormikTextField';
import { AiOutlineDelete } from 'react-icons/ai';
import AIProjectPlan from 'Components/Projects/ProjectPlan/AIProjectPlan';
import { INDUSTRY } from 'constants/contentTypes';
import onBoardingService from 'services/onBoardingService';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/slices/auth';
import { useSelector } from 'react-redux';
import Dropdown from 'Components/Dropdown/Dropdown';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { setLoader } from 'redux/slices/loader';
import * as Yup from 'yup';

const ExpandPlanDrawer = ({
  show,
  onHide,
  title,
  placement,
  width,
  callback,
  isProjectView,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    content_no: Yup.number()
      .required('Please select the number of content')
      .oneOf([5, 10, 20, 25], 'Invalid content number'),
    // content_goals: Yup.string().trim()
    //   .required('Content goals are required'),
    // .min(10, 'Content goals should be at least 10 characters long'),
    brand: Yup.string().required('Brand is required'),
  });

  const [showGeneratePlan, setShowGeneratePlan] = useState(false);
  const tags = [
    {
      id: 'linkedin',
      title: 'Linkedin Post',
      selected: false,
      icon: <Linkedin />,
    },

    {
      id: 'linkedin-article',
      title: 'Linkedin Article',
      selected: false,
      icon: <Linkedin />,
    },

    {
      id: 'facebook-post',
      title: 'Facebook Post',
      selected: false,
      icon: <Facebook />,
    },
    {
      id: 'instagram',
      title: 'Instagram Carousel',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'tiktok-video',
      title: 'TikTok Video',
      selected: false,
      icon: <TikTok />,
    },
    {
      id: 'instagram-post',
      title: 'Instagram Post',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'instagram-reel',
      title: 'Instagram Reel',
      selected: false,
      icon: <Instagram />,
    },
    { id: 'medium', title: 'Medium Article', selected: false, icon: <Other /> },
    { id: 'twitter', title: 'Twitter Thread', selected: false, icon: <X /> },
    {
      id: 'youtube',
      title: 'Youtube Script',
      selected: false,
      icon: <Youtube />,
    },
    { id: 'blog', title: 'Blog Post', selected: false, icon: <Blog /> },
    { id: 'case', title: 'Case Study', selected: false, icon: <CaseStudy /> },
    { id: 'guides', title: 'Guide', selected: false, icon: <Guide /> },
    { id: 'quiz', title: 'Quiz', selected: false, icon: <Quiz /> },
  ];

  const contentNo = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '25', value: 25 },
  ];
  const [fields, setFields] = useState([]);
  const [formValues, setFormValues] = useState({});

  const handleFieldChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, value } : field,
    );
    setFields(updatedFields);
  };

  const handleAddField = () => {
    const newField = { id: Date.now(), name: 'niche', value: '' };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const handleRemoveField = (index) => {
    if (fields.length === 1) {
      errorToast('Atleast 1 Topic is required');
      return;
    }
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const [tagState, setTagState] = useState(tags);

  const [brands, setBrands] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const handleTagSelection = (selectedTag) => {
    setTagState((prevTags) =>
      prevTags.map((tag) =>
        tag.id === selectedTag.id ? { ...tag, selected: !tag.selected } : tag,
      ),
    );
  };

  const generateBrief = (values) => {
    const topicsArray = fields.map((field) => field.value);
    const selectedTagIds = tagState
      .filter((tag) => tag.selected)
      .map((tag) => tag.title);
    setContentTypes(selectedTagIds);

    setFormValues({ ...values, topics: topicsArray });
    setShowGeneratePlan(true);
  };

  function transformBrandsData(brandsData) {
    return brandsData?.map((item) => {
      const [key, value] = Object.entries(item)[0];
      if (value === null) {
        return { label: 'Unknown', value: key };
      }
      return { label: value, value: key };
    });
  }

  const fetchBrandByID = async (brandId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching!',
          description: 'Please wait...',
        }),
      );
      const data = await onBoardingService.getBusinessById(brandId);
      setFields(
        data?.data?.content_topics.map((topic, index) => ({
          id: index,
          name: 'niche',
          value: topic,
        })),
      );
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      errorToast('Error while fetching Brand data');
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const [initialValues, setInitialValues] = useState({
    brand: '',
    content_goals: '',
    content_no: 5,
  });

  const fetchBrands = async () => {
    try {
      const response = await onBoardingService.getUserBrandInfo();
      setBrands(response?.data?.brands_data);
      setInitialValues((prevValues) => ({
        ...prevValues,
        brand: transformBrandsData(response?.data?.brands_data),
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  return (
    <>
      {' '}
      <Drawer
        show={show}
        onHide={onHide}
        title={title}
        placement={placement}
        width={width}
      >
        {!showGeneratePlan && (
          <Formik
            initialValues={initialValues}
            onSubmit={generateBrief}
            validationSchema={validationSchema}
          >
            {({ values, handleSubmit, submitForm, setFieldValue }) => {
              const handleClick = () => {
                submitForm();
              };
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  className={`${Styles.form4_wrapper} px-4 gap-4`}
                >
                  <Dropdown
                    name="brand"
                    label="Brand"
                    options={transformBrandsData(brands)}
                    isRequired
                    placeholder="Select a Brand"
                    value={
                      transformBrandsData(brands).find(
                        (option) => option.value === values.brand,
                      ) || ''
                    }
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        'brand',
                        selectedOptions ? selectedOptions.value : '',
                      );
                      fetchBrandByID(selectedOptions.value);
                    }}
                  />
                  <div>
                    <FormikTextField
                      id="content_goals"
                      name="content_goals"
                      type="text"
                      placeholder="i.e we need to Increase brand awareness with educational content..."
                      Label="What are your content goals?"
                      classList="input form-control text-area-height"
                      // isRequired
                      isTextArea
                    />
                    <ErrorMessage
                      name="content_goals"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  {/* add !isProjectView then show content type to hide it from campaign */}
                  <div className={Styles.form4_wrapper__tags_container}>
                    <label
                      htmlFor=""
                      className="Input_label form-label"
                      // style={{ fontWeight: '600' }}
                    >
                      Please let us know which type of content you want to
                      create.
                    </label>
                    <div
                      className={Styles.form4_wrapper__tags_container__outer}
                    >
                      {tagState?.map((tag) => (
                        <Tag
                          key={tag.id}
                          tag={tag}
                          setTags={setTagState}
                          tags={tagState}
                          Tagclasses={
                            Styles.form4_wrapper__tags_container__outer__tag
                          }
                          iconLeft={tag.icon}
                          selected={tag.selected}
                          onClick={() => handleTagSelection(tag)}
                        />
                      ))}
                    </div>
                  </div>
                  <Dropdown
                    name="content_no"
                    label="No. of Content"
                    options={contentNo}
                    isRequired
                    placeholder="Select an Option"
                    value={contentNo.find(
                      (option) => option.value === values.content_no,
                    )}
                    // value={values.content_no}
                    onChange={(selectedOption) =>
                      setFieldValue(
                        'content_no',
                        selectedOption ? selectedOption.value : '',
                      )
                    }
                  />

                  {fields.length > 0 && (
                    <div className={Styles.form4_wrapper__timeframe}>
                      <label
                        htmlFor=""
                        // className={`${Styles.textarea_wrapper__label} ${Styles.flex_label_button} Input_label`}
                        className={'Input_label form-label'}
                      >
                        <div>
                          Content Topics <span className="text-danger">*</span>
                        </div>
                      </label>
                      {fields.map((field, index) => {
                        return (
                          <div
                            key={field.id}
                            className={Styles.form3_wrapper__field}
                          >
                            <div className="w-100">
                              <input
                                type="text"
                                placeholder="Describe topic..."
                                className={Styles.input_content}
                                value={field.value}
                                onChange={(e) =>
                                  handleFieldChange(index, e.target.value)
                                }
                              />
                            </div>
                            <button
                              type="button"
                              className={Styles.form3_wrapper__field__button}
                              onClick={() => handleRemoveField(index)}
                            >
                              <AiOutlineDelete size={20} color="#667085" />
                            </button>
                          </div>
                        );
                      })}
                      <button
                        type="button"
                        className={Styles.form3_wrapper__another_field_btn}
                        onClick={handleAddField}
                      >
                        <FiPlus size={20} color="#00a7b7" />
                        <span>Add another content topic</span>
                      </button>
                    </div>
                  )}

                  <div className="d-flex justify-content-end align-self-end align-bottom drawer-footer">
                    <button
                      type="submit"
                      onClick={handleClick}
                      className={`secondary_btn`}
                    >
                      Generate Content
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        {!showGeneratePlan && (
          <ProgressBar now={46} className="drawer-progress-bar" />
        )}
        {showGeneratePlan && (
          <>
            <div className="px-3">
              <AIProjectPlan
                onboardingFooter={true}
                onHide={onHide}
                amount={formValues?.content_no}
                headlines={{ content_types: contentTypes }}
                brand={formValues?.brand}
                topics={formValues?.topics}
                goals={formValues?.content_goals}
                flow="expandPlan"
                callback={callback}
                // boostPlan={boostPlan}
              />
            </div>
            <div className="d-flex justify-content-end align-self-end align-bottom drawer-footer">
              {/* <button
              onClick={()=>boostPlan()}
              className={`cancel_btn`}
            >
              Boost Plan
            </button>
            <button
              // onClick={handleClick}
              className={`cancel_btn`}
            >
              Cancel
            </button> */}
              <button
                // type="submit"
                onClick={onHide}
                className={`cancel_btn`}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default ExpandPlanDrawer;