import React, { useCallback, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { FiPlus, FiRefreshCw, FiTrash } from 'react-icons/fi';
import { ErrorMessage, Formik } from 'formik';
import { debounce } from 'lodash';

import { CONTENT_TYPE_OPTIONS } from 'constants/contentTypes';
import { briefCreateSchema } from 'schemas/briefSchemas';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import TagInput from 'Components/Input/TagsInput/TagInput';
import Styles from './BriefDrawer.module.scss';
import Dropdown from 'Components/Dropdown/Dropdown';
import FormikDateField from 'Components/Form/FormikDateField';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import { FaChevronDown } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

const BriefForm = ({
  initialValues,
  priorityOptions,
  userOptions,
  stateOptions,
  teamOptions,
  documentOptions,
  isProjectView,
  setSelectedProject,
  buttonTitle,
  action,
  onSubmit,
  onHide,
  onDeleteHandler,
  taskId,
  setSearchDocs,
  disableCreateDraft = false,
  regenerate,
  regenerateFn,
}) => {
  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);
  const hasDraft = Boolean(initialValues.document_id);
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const debounceFn = useCallback(
    debounce((inputValue) => {
      setSearchDocs(inputValue);
    }, 500),
    [setSearchDocs],
  );

  const [moreFields, setMoreFields] = useState(true);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={briefCreateSchema}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isValid, dirty }) => {
          const {
            content_type,
            state,
            keywords,
            priority,
            assignee_id,
            project_id,
            document_id,
          } = values || {};

          return (
            <div className="d-flex flex-column gap-5">
              <Form
                className={Styles.Container}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div>
                  <FormikTextField
                    name="title"
                    type="string"
                    placeholder="Add Brief Title"
                    Label="Topic/Headline"
                    classList="input"
                    isRequired
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className={Styles.Container_TwoColumn}>
                  <Dropdown
                    name="content_type"
                    label="Content Type"
                    placeholder="Select content type"
                    options={CONTENT_TYPE_OPTIONS}
                    isRequired
                    value={CONTENT_TYPE_OPTIONS.find(
                      (option) => option.value === content_type,
                    )}
                    onChange={({ value }) =>
                      setFieldValue('content_type', value)
                    }
                    iconWithOption={true}
                  />
                  <div></div>
                </div>
                <div>
                  <FormikTextField
                    id="description"
                    name="description"
                    type="text"
                    placeholder="Add Brief Description"
                    Label="Description"
                    classList="input form-control text-area-height"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className={Styles.Container_TwoColumn}>
                  <div>
                    
                    <FormikTextField
                      id="content_structure"
                      name="content_structure"
                      type="text"
                      placeholder="Define any custom instructions"
                      Label="Custom Instructions"
                      classList={`input form-control ${Styles.customInstructionsArea}`}
                      isTextArea
                    />

                    <ErrorMessage
                      name="content_structure"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <TagInput
                    label="Keywords to Use"
                    tags={keywords}
                    onChange={(value) => setFieldValue('keywords', value)}
                  />
                </div>
                <div className={Styles.Container_TwoColumn}>
                  <div>
                    <FormikTextField
                      name="target_audience"
                      type="string"
                      placeholder="Add Audience"
                      Label="Target Audience"
                      classList="input"
                    />
                    <ErrorMessage
                      name="target_audience"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  <FormikTextField
                    name="word_count"
                    type="number"
                    placeholder="Add Word Count"
                    Label="Word Count"
                    classList="input"
                  />
                </div>
                <div
                  className={`${Styles.Container_TwoColumn} ${Styles.Container_background_color}`}
                >
                  <button
                    type="button"
                    className={Styles.more_options}
                    onClick={() => setMoreFields((prevState) => !prevState)}
                  >
                    <span className={Styles.more_options__heading}>
                      Advanced Options{' '}
                    </span>

                    <FaChevronDown
                      className={moreFields ? '' : Styles.rotate}
                    />
                  </button>
                </div>
                {/* break form here */}
                <div
                  className={`p-0 m-0 ${moreFields ? 'd-none' : 'd-flex'} ${Styles.Container}`}
                >
                  <div className={Styles.Container_TwoColumn}>
                    <div>
                      <Dropdown
                        name="state"
                        label="Status"
                        options={stateOptions}
                        // isRequired
                        value={stateOptions.find(
                          (option) => option.value === state,
                        )}
                        onChange={(option) =>
                          setFieldValue('state', option?.value ?? '')
                        }
                      />
                    </div>
                    <div>
                      <Dropdown
                        name="priority"
                        label="Priority"
                        options={priorityOptions}
                        // isRequired
                        value={priorityOptions.find(
                          (option) => option.value === priority,
                        )}
                        onChange={({ value }) =>
                          setFieldValue('priority', value)
                        }
                      />
                    </div>
                  </div>
                  <div className={Styles.Container_TwoColumn}>
                    {!isProjectView && (
                      <div>
                        <Dropdown
                          name="project_id"
                          label="Select Campaign"
                          placeholder="Select Campaign"
                          options={teamOptions}
                          isClearable
                          value={teamOptions.find(
                            (option) => option.value === project_id,
                          )}
                          onChange={(option) => {
                            setFieldValue('project_id', option?.value ?? '');
                            setSelectedProject(option?.value ?? '');
                            setFieldValue('document_id', '');
                            !option?.value && setFieldValue('assignee_id', '');
                          }}
                          isDisabled={
                            currentSubscription?.plan_name === 'Basic Plan' &&
                            !isProjectView
                              ? true
                              : false
                          }
                        />
                      </div>
                    )}
                    <FormikDateField name="deadline_date" label="Due Date" />
                  </div>
                  <div className={Styles.Container_TwoColumn}>
                    <Dropdown
                      name="assignee_id"
                      label="Assign to"
                      placeholder="Select User"
                      options={userOptions}
                      isClearable
                      value={userOptions.find(
                        (option) => option.value === assignee_id,
                      )}
                      onChange={(option) =>
                        setFieldValue('assignee_id', option?.value ?? '')
                      }
                    />
                    <Dropdown
                      name="document_id"
                      label="Link Draft"
                      placeholder="Select Document"
                      options={documentOptions}
                      isClearable
                      value={documentOptions.find(
                        (option) => option.value === document_id,
                      )}
                      onChange={(option) => {
                        setFieldValue('document_id', option?.value ?? '');
                      }}
                      onInputChange={debounceFn}
                    />
                  </div>
                </div>
              </Form>
              {action === 'create' && (
                <ProgressBar now={66} className="drawer-progress-bar" />
              )}
              <div
                className={`d-flex drawer-footer ${
                  action === 'edit'
                    ? 'justify-content-between'
                    : 'justify-content-end'
                }`}
              >
                {action === 'edit' && (
                  <BSButton
                    classList={`button deleteBtn ${Styles.Container_DeleteButton}`}
                    variant="outline-danger"
                    ButtonText="Delete"
                    iconPosition="right"
                    icon={<FiTrash size={20} />}
                    onClick={() => {
                      onDeleteHandler(taskId);
                    }}
                  />
                )}
                <div className={Styles.Container_ButtonContainer}>
                  {action === 'edit' && !hasDraft && !disableCreateDraft && (
                    <BSButton
                      classList="button"
                      variant="outline-secondary"
                      ButtonText="Create Draft"
                      icon={<FiPlus size={20} />}
                      onClick={() => {
                        setShowNewDocumentModal(true);
                      }}
                    />
                  )}
                  {regenerate && (
                    <BSButton
                      classList="button secondary_btn"
                      variant="light"
                      ButtonText="Regenerate"
                      onClick={(e) => {
                        regenerateFn(values);
                        onHide();
                      }}
                      icon={<FiRefreshCw />}
                      disabled={!(isValid && dirty)}
                    />
                  )}

                  <BSButton
                    classList="button poppins_semibold secondary_btn"
                    variant=""
                    ButtonText={buttonTitle}
                    onClick={(e) => {
                      onSubmit(action, values);
                      onHide();
                    }}
                    disabled={!isValid}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>

      {action === 'edit' && !hasDraft && showNewDocumentModal && (
        <NewDocumentModal
          show={showNewDocumentModal}
          onHide={() => {
            setShowNewDocumentModal(false);
          }}
          taskId={taskId}
          taskName={initialValues.title}
          projectId={initialValues.project_id}
          aiPowered
        />
      )}
    </>
  );
};

export default BriefForm;
